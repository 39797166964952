/* header */
header {
  margin-top: 90px;
  margin-bottom: 30px;
}

.header_title {
  font-size: 4.5rem;

  /* --------- */
  background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header_para {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.4rem;
}

/* main section */

/* video section */
.video-section {
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn_section {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.btn_section button {
  margin-right: 1rem;
}

.btn_section a {
  text-decoration: none;
  margin-right: 1rem;
}

/* Partners & Collaborators */
.partners_collaborators {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
  flex-wrap: wrap;
}

/* why shiom section */

.features {
  margin-top: 100px;
}

.features_title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.features_title h2 {
  font-size: 40px;
}

.features_body {
  margin-top: 90px;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  background-image: url("../../assets/svg/bg-1.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}

.features_card {
  max-width: 260px;
  height: 200px;
  padding: 1.3rem;
  border: 1px solid var(--border-gray);
  border-radius: 10px;
  background-color: var(--neutral-black);
  /* backdrop-filter: blur(4px); */
  box-shadow: 0px 0px 20px 16px rgba(26, 26, 26, 0.38);
}

/* demo image section */
.demo_img_section {
  margin: 50px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.demo_img_section img {
  width: 100%;
  border-radius: 5px;
}

.demo_img_section:nth-child(3) img {
  grid-column: 2;
  grid-row: 1;
}

.img_gallery_card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--border-gray);
  border-radius: 10px;
  padding: 1.5rem 1rem;
}

/* youtube podcast ref section */
.yt_podcast {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* shiom different vertical section */
.vertical_section {
  margin-top: 80px;
  padding: 15px;
}
span.color-yellow {
  color: #fca311;
}
.card_wrapper {
  margin: 30px 0;
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* justify-content: space-around; */
  justify-items: center;
  align-items: center;
}

.card {
  padding: 1rem;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100px;
  cursor: pointer;
}

.card img {
  filter: none;
  transition: all 0.7s ease-in-out;
  /* cursor: pointer; */
}

.card p {
  height: 38px;
  visibility: hidden;
  transition: all 3ms ease-in-out;
}

.card:hover.card img {
  transform: scale(0.9);
}

.card:hover.card p {
  visibility: visible;
}

/* subscribe section or sign up section */
.subscribe_section {
  min-height: 40vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  padding: 2rem;
}

.subscribe_left h3 {
  font-size: 2em;
}

.social_links {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 18px;
}

.subscribe_right {
  display: flex;
  gap: 10px;
}

.subscribe_right input {
  padding: 20px 10px;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 1.3rem;
  font-family: "Roboto Mono", monospace;
}

.subscribe_right button {
  font-family: "Roboto Mono", monospace;

  font-size: 1.5rem;
  width: 200px;
}

/* side nav social links */
.side_social_links {
  z-index: 100;
  position: fixed;
  top: 30%;
  right: 0;
  border: 1px solid var(--border-gray);
  border-radius: 1rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* responsive for small screen sizes */
@media screen and (max-width: 1000px) {
  .video-section {
    width: 600px;
  }

  .features_body {
    justify-content: space-around;
  }

  .demo_img_section {
    padding: 1rem;
    grid-template-columns: 1fr;
  }

  .demo_img_section:nth-child(3) img {
    grid-column: 1;
  }
}

@media screen and (max-width: 750px) {
  .video-section {
    width: 100%;
    padding: 15px;
  }

  .card_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .yt_podcast {
    grid-template-columns: 1fr;
  }

  .subscribe_section {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .social_links {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .side_social_links {
    top: 20%;
  }
}

@media screen and (max-width: 500px) {
  .header_title {
    font-size: 3.2rem;
    padding: 5px;
  }

  .btn_section {
    justify-content: space-around;
  }

  .btn_section button {
    margin-right: 0;
  }

  .subscribe_right {
    flex-direction: column;
    align-items: center;
  }

  .footer_content {
    flex-direction: column;
  }
}
