@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap");

:root {
  /* --neutral-black: #000; */
  /* --neutral-black: #22272e; */
  --neutral-black: #1a1a1a;
  --neutral-white: #fff;
  --light-yellow: #fca311;
  --light_gray: #e5e5e5;
  --border-gray: rgba(84, 84, 84, 0.48);

  --success-color: #2ecc71;
  --error-color: #e74e3c;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--neutral-black);
  color: var(--neutral-white);
  font-family: "Montserrat", sans-serif;
}

/* custom scrollbar */
html {
  scrollbar-width: 0.5vw;
  scrollbar-color: #777 var(--neutral-black);
}

html::-webkit-scrollbar {
  width: 0.5vw;
}

html::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 5px;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto Mono", monospace;
}

p {
  color: var(--light_gray);
}

/* utils */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}

.my-1 {
  margin: 1rem 0;
}

.p-1 {
  padding: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn-primary {
  background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
  color: var(--neutral-white);
}

.btn-text {
  background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.gap-10 {
  gap: 10px;
}

.highlight-text {
  color: var(--neutral-white);
  font-size: 1.8rem;
}
