/* footer section */

.footer_content {
  border-top: 1px solid var(--border-gray);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visitors {
  color: var(--border-gray);
}
