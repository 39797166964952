/* navbar */
.navbar {
  height: 80px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_brand {
  width: 200px;
}

.nav_brand img {
  width: 100%;
  height: 100%;
}

.nav_links a {
  text-decoration: none;
  color: var(--neutral-white);
}
