.cookieModal {
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  background-color: #f1f6fe;
  padding: 2rem;
  border-radius: 10px;
  /* transition: all 5ms ease-in-out; */
  animation: slidein 3s;
}

.cookie-head {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cookie-head img {
  width: 50px;
  height: 50px;
}

.cookie-text {
  color: var(--neutral-black);
}

.cookie-btns {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.cookie-btns button:nth-child(1) {
  margin-right: 1.5rem;
}

/* keyframes animation */
@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

/* responsive */
@media screen and (max-width: 1200px) {
  .cookieModal {
    margin: 0 1rem;
  }
}
