.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal_container {
  position: fixed;
  bottom: 0;
  background-color: #545454;
  padding: 50px;
  width: 50vw;
  border-radius: 1rem 1rem 0 0;
  z-index: 1000;
  animation: slidein 0.7s ease-in-out;
}

/* modal form */
.form_group {
  margin: 0.5rem 0;
}

.form_label {
  display: block;
  padding-left: 10px;
}

.form_input {
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem 0.5rem;
  border-radius: 0.6rem;
  border: 1px solid var(--neutral-black);
  background-color: var(--neutral-black);
  color: var(--neutral-white);
  font: inherit;
}

.form_input:focus {
  outline: none;
  border: 1px #fff solid;
}

.form_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-danger {
  background-color: var(--error-color);
  color: var(--neutral-white);
}

/* form validation */
.form_group {
  position: relative;
}

.form_group.success .form_input {
  border-color: var(--success-color);
}

.form_group.error .form_input {
  border-color: var(--error-color);
}

.form_group i {
  visibility: hidden;
  position: absolute;
  top: 45px;
  right: 15px;
}

.form_group.success i.fa-check-circle {
  color: var(--success-color);
  visibility: visible;
}

.form_group.error i.fa-exclamation-circle {
  color: var(--error-color);
  visibility: visible;
}

.form_group small {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

.form_group.error small {
  position: relative;
  color: var(--error-color);
  visibility: visible;
  padding-left: 10px;
}

.s-msg {
  background-color: rgba(46, 204, 113, 0.15);
  color: var(--success-color);
  width: 100%;
  display: inline-block;
  padding: 0.4rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.error-msg {
  background-color: rgba(240, 46, 46, 0.15);
  color: var(--error-color);
  width: 100%;
  display: inline-block;
  padding: 0.4rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

/* keyframes */
@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 1000px) {
  .modal_container {
    width: 70vw;
  }
}

@media screen and (max-width: 900px) {
  .modal_container {
    width: 100vw;
  }
}

@media screen and (max-width: 500px) {
  .modal_container {
    padding: 50px 15px;
  }
}
