.error_page {
  margin-top: 80px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.error_page_left img {
  width: 100%;
}

.error_page_right h1 {
  font-size: 5em;
}

@media screen and (max-width: 950px) {
  .error_page {
    grid-template-columns: 1fr;
    height: auto;
  }
}
